
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: 'Love Ya Like A Sister', cursive;
}
h2 {
  font-family: 'Love Ya Like A Sister', cursive;
}
p {
  font-family: 'Josefin Sans', sans-serif;
}